import React from 'react'
import {
  CARROM,
  CARROM_LEARN,
  CASUAL,
  GAMES,
} from '../../../components/internal-links'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import { CARROM_LEARN_PAGES } from '../../../carrom-tags'
import Blogs from '../../../components/Blogs/Blogs'
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs'
import { generateBreadcrumbsSchema } from '../../../lib/generate-schema'
import { LATEST_RC as CARROM_DOWNLOAD_LINK } from '../../../components/download-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Games',
    url: GAMES,
  },
  {
    title: 'Casual',
    url: CASUAL,
  },
  {
    title: 'Carrom',
    url: CARROM,
  },
  {
    title: 'Learn Carrom',
    url: CARROM_LEARN,
  },
]

const LearnCarrom: React.FC = () => {
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={CARROM_DOWNLOAD_LINK}
    >
      <SEO
        title="Learn All About Carrom: A comprehensive guide to the world of Carrom | Mega"
        description="Now get the answers to all your questions regarding carrom. Read a bunch of articles containing all information about carrom, gameplay &amp; much more."
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <Blogs
        blogType="carrom"
        tags={CARROM_LEARN_PAGES}
        prefix={`${CARROM}${process.env.GATSBY_CMS_LEARN_CARROM_PREFIX}` || '/'}
        title={
          <>
            Learn Carrom - <span className="underline">Guide</span>
          </>
        }
      />
    </Layout>
  )
}

export default LearnCarrom
